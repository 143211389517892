/** @format */

.q-segment {
	position: relative;
	text-align: center;
	background-position: center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-blend-mode: color-dodge;
	min-width: 100%;
	min-height: 600px;
	background-size: cover;
}

.q-overlay {
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0.8) 0%,
		rgba(0, 0, 0, 0.73) 17%,
		rgba(0, 0, 0, 0.66) 35%,
		rgba(0, 0, 0, 0.55) 62%,
		rgba(0, 0, 0, 0.4) 100%
	);
	min-height: 600px;
}

blockquote {
	font-family: Georgia, serif;
	font-size: 18px;
	font-style: italic;
	width: 85%;
	margin: 0.25em 0;
	padding: 0.35em 40px;
	line-height: 1.45;
	color: #cacaca;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	/* text-align: left; */
	text-shadow: 1px 1px 20px #000;
	/* box-shadow: 1px 1px 8px #000; */
	/* background-color: rgb(0, 0, 0, 0.6); */
	/* border-radius: 8px; */
}

blockquote:before {
	/* display: block; */
	padding-left: 10px;
	/* content: '\201C'; */
	font-size: 80px;
	position: absolute;
	left: -20px;
	top: -20px;
	color: #cacaca;
}

blockquote cite {
	color: #ffffff;
	font-size: 14px;
	display: block;
	margin-top: 5px;
	/* text-align: right; */
}

blockquote cite:before {
	content: '\007e';
}

q:before {
	content: none;
}
