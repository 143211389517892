/** @format */

.ref-container {
	position: relative;
	text-align: center;
	background-color: rgb(255, 255, 255, 0.6);
	background-position: center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	/* background-blend-mode: color-burn; */
	background-size: cover;
	min-width: 75%;
	min-height: 50%;
	padding: 50px;
	overflow: auto;
}

.ui.attached.label.platform {
	background-color: transparent;
}

.ui.three.cards > .card {
	padding: 20px !important;
}

.header {
	font-size: 0.95em !important;
}

.meta {
	font-size: 0.7em !important;
}

h1.ui.header {
	font-size: 2em !important;
}
